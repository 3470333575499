<template>
  <Dialog v-model:visible="displayModal" :breakpoints="{ '960px': '95vw' }" :closable="false"
          style="width: 60%;background:#FFFFFF 0% 0% no-repeat padding-box;border-radius:30px;"
          :modal="true" class="modal-publications justify-center">
    <!--    <template #header>-->
    <div class="justify-center" style="color:#1c3faa;">
      <div class="">
        <div class="heaeder-img-modal">
          <img class="w-6" src="@/assets/images/icons/modal-createpub-icon.png"/>
        </div>
        <span class="text-base header-modal"><strong
          class="pr-2">{{ medicamento.ItemCode + ' - ' + medicamento.ItemName }}</strong></span>
      </div>
      <div class="flex justify-center">
        <span class="pr-4"><strong class="pr-2 text-modalCant">Total Cantidad: </strong><strong
          class="text-red-800">{{ totalCantidades }}</strong></span>
        <span><strong class="pr-2 text-modalCant">Conversión: </strong><strong
          class="text-red-800">{{ medicamento.U_PHR_Conversion }}</strong></span>
      </div>
    </div>
    <!--    </template>-->
    <form action="">
      <Card>
        <template #content>
          <DataTable
            :value="cantidadMx"
            class="p-datatable-sm text-xs"
            showGridlines
            dataKey="U_PHR_WarehouseCodeOri"
            responsiveLayout="scroll"
            editMode="row"
            v-model:editingRows="editingRows"
            scrollHeight="500px"
          >
            <template #empty>
              No hay datos para mostrar
            </template>
            <Column field="U_PHR_WarehouseCodeOri" header="Bodega" headerStyle="justify-content: center;"
                    style="min-width:1rem"
                    bodyStyle="text-align: center">
              <template #editor="{data}">
                <p class="text-xs">
                  {{ data.U_PHR_WarehouseCodeOri + ' - ' + data.WhsName }}
                </p>
              </template>
              <template #body="{data}">
                <p class="text-xs">
                  {{ data.U_PHR_WarehouseCodeOri + ' - ' + data.WhsName }}
                </p>
              </template>
            </Column>
            <Column field="TempQuantity" header="Cantidad" headerStyle="justify-content: center;" style="min-width:1rem"
                    bodyStyle="text-align: center">
              <template #editor="slotProps">
                <InputNumber v-model="slotProps.data.TempQuantity" :step="medicamento.U_PHR_Conversion"
                             class="p-inputtext-xs w-12" inputId="minmax" showButtons buttonLayout="horizontal"
                             decrementButtonClass="bg-blue-800" incrementButtonClass="bg-blue-800"
                             incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                             :min="(Math.ceil(slotProps.data.QuantitySumOriginal/medicamento.U_PHR_Conversion)) * medicamento.U_PHR_Conversion"
                             :readonly="true" inputStyle="background-color: #eee;pointer-events: none;"/>
              </template>
            </Column>
            <Column field="DocNumPedido" header="Info. Solicitud" headerStyle="justify-content: center;"
                    style="min-width:1rem"
                    bodyStyle="text-align: center">
              <template #editor="{data}">
                <div class="grid grid-cols-2" v-for="(solicitud, key) in data.infoSolicitudes" :key="key">
                  <p class="text-xs">
                    <strong>Nro. Solicitud: </strong> {{ solicitud.U_PHR_BaseRef }}
                  </p>
                  <p class="text-xs">
                    <strong>Cantidad Original: </strong>{{ solicitud.QuantitySolicitud }}
                  </p>
                </div>
              </template>
              <template #body="{data}">
                <div class="grid grid-cols-2" v-for="(solicitud, key) in data.infoSolicitudes" :key="key">
                  <p class="text-xs">
                    <strong>Nro. Solicitud: </strong> {{ solicitud.U_PHR_BaseRef }}
                  </p>
                  <p class="text-xs">
                    <strong>Cant. Original: </strong>{{ $h.formatNumber(solicitud.QuantitySolicitud) }}
                  </p>
                </div>
              </template>
            </Column>
            <Column header="Acciones" :rowEditor="true" style="width:10%; min-width:8rem"
                    bodyStyle="text-align:center"></Column>
          </DataTable>
        </template>
      </Card>
    </form>
    <template #footer class="border-modal-footer">
      <div style="display: flex;justify-content: center;">
        <Button label="Cancelar" @click="closeModal" class="p-button-secondary btn-footer-modal-cancel"/>
        <Button label="Asignar" @click="onSubmit()" class="p-button-danger btn-footer-modal-delete"
                autofocus/>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { computed, ref } from 'vue'
import Swal from 'sweetalert2'
import storeConfirmacionPedido from '@/apps/pharmasan/compras/ofertas/store/ofertas.store'

export default {
  name: 'modalCantidades',
  emits: ['refrescar'],
  setup (props, { emit }) {
    const medicamento = ref({})
    const displayModal = ref(false)
    const editingRows = ref([])
    const DocEntry = ref(0)
    const cantidadMx = ref([])
    const totalCantidades = computed(() => {
      return cantidadMx.value.reduce((total, current) => {
        if (current.TempQuantity === null) current.TempQuantity = 0
        return total + parseInt(current.TempQuantity)
      }, 0)
    })
    const openModalCant = (med, data, docEntry) => {
      editingRows.value = []
      medicamento.value = med
      cantidadMx.value = data
      DocEntry.value = docEntry
      displayModal.value = true
    }
    const closeModal = () => {
      displayModal.value = false
    }
    const onSubmit = () => {
      if (editingRows.value.length > 0) {
        Swal.fire({
          icon: 'info',
          text: 'Hay cantidades de bodega por confirmar, por favor revisar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      Swal.fire({
        icon: 'question',
        title: 'Información',
        html:
          `¿Desea confirmar la cantidad:  <strong>${totalCantidades.value}</strong><br> Médicamento: <strong>${medicamento.value.ItemCode} - ${medicamento.value.ItemName}</strong>`,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'No, cancelar',
        confirmButtonText: 'Si, asignar'
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          cantidadMx.value.forEach((element) => {
            element.onlyItemCode = false
          })
          const params = {
            DocEntry: DocEntry.value,
            detalle: cantidadMx.value.map((x) => {
              return {
                Quantity: x.TempQuantity,
                LineNumOferta: x.infoSolicitudes[0].LineNumOferta,
                U_PHR_WarehouseCodeOri: x.U_PHR_WarehouseCodeOri
              }
            })
          }
          await storeConfirmacionPedido.dispatch('updateModalCantidades', params).then(({ data }) => {
            if (data[0].affected) {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'Se actualizó la cantidad Correctamente',
                showConfirmButton: true,
                confirmButtonText: 'Ok'
              }).then((resp) => {
                if (resp.isConfirmed) {
                  emit('refrescar')
                  displayModal.value = false
                }
              })
            }
          })
        }
      })
    }
    return {
      openModalCant,
      displayModal,
      medicamento,
      closeModal,
      cantidadMx,
      editingRows,
      totalCantidades,
      onSubmit
    }
  }
}
</script>

<style scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

centrar-header {
  justify-content: center !important;
}

::v-deep(.p-inputnumber-input) {
  width: 3rem;
}

::v-deep(.p-inputnumber-buttons-horizontal) {
  justify-content: center;
}

.text-modalCant {
  color: #7F7E81;
}
</style>
